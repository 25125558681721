import React from "react";
import { Link } from "gatsby";
import { getFeaturedImgSrc } from "../utils";

const Articles = ({ articles }) => {
  return (
    <div
      className="uk-child-width-1-2@s uk-child-width-1-3@m"
      uk-grid="masonry: true"
    >
      {articles.map((article, i) => {
        return (
          <div
            key={article.node.strapiId}
            className="uk-flex uk-flex-center uk-flex-middle"
          >
            <div className="uk-card uk-card-default">
              <Link to={`/article/${article.node.slug}`}>
                <div className="uk-card-media-top card-img-container">
                  <img
                    style={{
                      objectFit: "cover",
                      height: "100%",
                      width: "100%",
                    }}
                    src={getFeaturedImgSrc(article)}
                    alt={article.node.title}
                  />
                </div>
              </Link>
              <div className="uk-card-body">
                <Link to={`/article/${article.node.slug}`}>
                  <h2 className="uk-card-title">{article.node.title}</h2>
                </Link>
                <p>{article.node.description}</p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Articles;
