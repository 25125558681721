export const getFeaturedImgSrc = (article) => {
  const recommendedProductsList = article.node.products.filter(
    (prod) => prod.recommended > 0
  );
  const featuredProd = recommendedProductsList.length
    ? recommendedProductsList[0]
    : article.node.products[0];

  return featuredProd.isAmazonProduct
    ? `https://m.media-amazon.com/images/I/${featuredProd.imgSrc}._SL800_.jpg`
    : featuredProd.imgSrc;
};
